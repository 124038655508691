import './App.css';
import React, { useState } from 'react';
import ReactCountdownClock from 'react-countdown-clock';

function App() {
	const [isPaused, setIsPaused] = useState(true);
	const [count, setCount] = useState(0);
	const [seconds, setSeconds] = useState(10);
	
	const togglePaused = () => {
		setIsPaused(!isPaused);
	};
	
	const countdownDone = () => {
		setIsPaused(true);
		setCount(count + 1);
	};
	
	return (
		<>
			<div style={{display: 'flex', justifyContent: 'center', marginTop: '100px'}}>
				<ReactCountdownClock
					key={count}
					seconds={seconds}
					color="#009D4E"
					alpha={0.9}
					size={400}
					paused={isPaused}
					onComplete={countdownDone}
					style={{margin: '0 auto'}} />
			</div>
			<div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
				<h1 style={{fontSize: '4em'}}>
				{
					new Intl.NumberFormat('nl-NL', {
					  style: 'currency',
					  currency: 'EUR',
					}).format((count+1)*1.5)
				}
				</h1>
			</div>
			<div style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
				<button onClick={togglePaused} style={{borderRadius: '10px', border: '0px', fontSize: '2em', padding: '1em'}}>
					Start / pauze
				</button>
			</div>
		</>
	);
}

export default App;
